//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React from 'react';
import {Login} from '../pages/Login/Login';
import LoginLayout from "../layouts/LoginLayout";
import axios from '../utils/axios';
import { bConst, bMess, setVersionStr, buildNr } from '../constants';
import useSessionStorage from '../hooks/useSessionStorage';
import jwtDecode from 'jwt-decode';
import { Backdrop, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext(null);

const AuthProvider = (props) => {
  const sessionStorage = useSessionStorage();
  const [ refreshtoken, setRefreshToken ] = React.useState(undefined);
  const [ accesstoken, setAccessToken ] = React.useState(undefined);
  const [ getTokens, setGetTokens ] = React.useState(true);
  const [ user, setUser ] = React.useState(undefined);
  const [ settings, setSettings ] = React.useState(undefined);
  const [ sessionId, setSessionId] = React.useState(undefined);
  const [ loading, setLoading ] = React.useState(true);
  const [ errorMsg, setErrorMsg ] = React.useState("");
  const [ pwNeeded, setPwNeeded] = React.useState(true);
  const [ newPwNeeded, setNewPwNeeded] = React.useState(true);
  const [ tfaVerified, setTfaVerified ] = React.useState(false);
  const [ QR, setQR ] = React.useState(undefined);
  const [ scanQR, setScanQR ] = React.useState(undefined);

  const navigate = useNavigate();

  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    }
  };

  const now = new Date().toLocaleTimeString();
  let [time, setTime] = React.useState(now);
  
  React.useEffect(() => {
    const interval = setInterval(() => {
         const newTime = new Date().toLocaleTimeString();
         setTime(newTime);
    }, bConst.hbTime*1000)  // in mSec

    return () => {
      clearInterval(interval);
      if (user && sessionId) {
        axios.post ("/api/auth/set_session_time", 
          { sessionId: sessionId}, 
          { headers: {authorization: JSON.stringify({accountKey: user.accountKey})}}              
        )
      }   
    }
  }, [time])

  const fillQR = (status) => {  // fill QR en return verified status
    let result=tfaVerified 
        
    if (status===bConst.tfa_Not) { setTfaVerified(true); result=true
    } else {
       if (status===bConst.tfa_HasKey) { setQR(true) } else { setQR(false) }
    } 

    if (process.env.NODE_ENV === 'development') { setTfaVerified(true); result=true }  // %AvdH Uitgeschakeld voor testing !!!

    return (result)
  } 

  const setVerified = async () => {
    // await get_webtokens()     
    setTfaVerified(true)
    sessionStorage.save(bConst.tfaVerified, bConst.tfaValue) 
  }

  const login = React.useCallback( async (account, username, password) => {
    try {
      if( !account || !username || !password ) {
        return false;
      }
      setGetTokens(false)
      setLoading(true);
      setErrorMsg("");

      const ver=await axios.post("/versionStr", axiosConfig);
      setVersionStr(ver.data+'/'+buildNr);

      await axios.post("/api/auth/sign-in", 
        { account, userName: username, password}, axiosConfig 
      )
      .then((response) => {
        if( response.data && response.data.status === bConst.s_Success ) {
          setUser(response.data.result.user)
          setSettings(response.data.result.settings)
          setSessionId(response.data.result.sessionId)

          const token = fillQR(response.data.result.user.tfaStatus)
          
          sessionStorage.save(bConst.accountKey, response.data.result.user.accountKey);
          Cookies.set(bConst.bloqsAccount, account, { expires: 365 }); // expires in 365 days

          if (response.data.result.user.saveUserName===1) {
            Cookies.set(bConst.bloqsUserName, username, { expires: 365 }); // expires in 365 days
          } else {
            Cookies.set(bConst.bloqsUserName, '', { expires: 365 }); // expires in 365 days
          }
    
          if (response.data.result.user.isMedw===1) { 
            setNewPwNeeded(false) 
          } else { 
            setNewPwNeeded((new Date(response.data.result.user.pwDate)<=new Date()))
          }

          setPwNeeded(false)
          setLoading(false);

          if (token) {
            axios.post ("/api/auth/set_session_status", 
              { sessionId: response.data.result.sessionId, status: bConst.sb_Running}, 
              { headers: {authorization: JSON.stringify({accountKey: response.data.result.user.accountKey})}}              
            )
            .then((response) => { })
            .catch((error) => { } )
          }

          return response.data.result.user;
        } else { 
          setAccessToken(undefined);
          setRefreshToken(undefined);
          setUser(undefined);
          setSettings(undefined);
          setLoading(false);
          setErrorMsg( bMess[response.data.status] );
          return response.data.status;
        }
      })
      .catch((error) => {
        console.error('AuthContex.login:', error)
      })

    } catch(err) {
      setLoading(false);
      console.error("authcontext error", err);
    }
    setLoading(false);
  }, [sessionStorage]);

  const logout = React.useCallback( () => {  // volledig uitgelogd
    logout2()        
    setTfaVerified(false)
    sessionStorage.remove(bConst.tfaVerified)
  }, [sessionStorage]);
    
  const logout2 = React.useCallback( async () => { //uitloggen maar verificatie behouden
    if (sessionId) {
      axios.post("/api/auth/sign-out", { sessionId },
        { headers: {authorization: JSON.stringify({accountKey: sessionStorage.fetch(bConst.accountKey)})}}
      )  
    }
            
    setPwNeeded(true)
    setErrorMsg('');
    setAccessToken(undefined)    
    setRefreshToken(undefined)
    setUser(undefined)
    setSettings(undefined);
    setSessionId(undefined)
    sessionStorage.remove(bConst.refreshToken)
    sessionStorage.remove(bConst.accessToken)
    sessionStorage.remove(bConst.accountKey)
    navigate("/")
  }, [sessionStorage]);
    
  const verifyTfa = async (token) => {
    let verified = false
    const response = await axios.post( "/api/auth/verify_tfa", {"isMedw": user.isMedw, "userId": user.id, "token": token, "sessionId": sessionId},
                                      { headers: {authorization: JSON.stringify({accountKey: sessionStorage.fetch(bConst.accountKey)})}}
                                     ) 

    if (response.data && response.data.status === bConst.s_Success ) {
      if (response.data.result===true) {
        setTfaVerified(true)
        sessionStorage.save(bConst.tfaVerified, bConst.tfaValue) 
        verified=true
      } else {
        setTfaVerified(false) 
        sessionStorage.remove(bConst.tfaVerified) 
        setErrorMsg('De code is niet correct.') 
      }
    } else {
      setErrorMsg('Er is een fout opgetreden bij de verificatie van de code.')
    }
    return( verified )
  } 


  const get_webtokens = async () => {
    let tokens = false
    try {
      if (!getTokens) {  
        setGetTokens(true)
        await axios.post( "/api/auth/get_webtokens", {"userId": user.id, "isMedw": user.isMedw},
                          { headers: {authorization: JSON.stringify({accountKey: sessionStorage.fetch(bConst.accountKey)})}}
                        )
        .then((response) => {
            
          if (response.data && response.data.status === bConst.s_Success && response.data.result) {
            const {accessToken, refreshToken} = response.data.result;
            // console.log('get_tokens end: '+accessToken+' / '+refreshToken)
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            setGetTokens(false)
            sessionStorage.save(bConst.accessToken, accessToken);
            sessionStorage.save(bConst.refreshToken, refreshToken);
            tokens =true
          } else {
            tokens = false
          }       
        })
        .catch ((error) => {
          tokens = false
        })
      } else {
        tokens = false
      }
    } catch {
      tokens = false
    }
    return tokens
  } 

  const saveTfa = async(tfaKey) => {
    let result = false
    await axios.post("/api/auth/save_tfa", {"isMedw": user.isMedw, "userId": user.id, "tfaKey": tfaKey}, {
      headers: {
      authorization: JSON.stringify({accessToken: sessionStorage.fetch(bConst.accessToken) || "", accountKey: sessionStorage.fetch(bConst.accountKey)}),
      }, 
    })
     .then((response) => {
      if (response.data.status===bConst.s_Success) { 
        setQR(true)
        // console.log('QR saved: ')
        result = true
      } else {
        console.error('AuthContex.saveTfa:', response.data.status)
        result = false; 
      } 
    })
    .catch((error) => {
      console.error('AuthContex.saveTfa:', error)
      result = false;
    })

    return result
  }

  const savePW = async(PW) => {
    let result = false
    await axios.post("/api/auth/save_password", {"isMedw": user.isMedw, "userId": user.id, "password": PW}, {
      headers: {
      authorization: JSON.stringify({accessToken: sessionStorage.fetch(bConst.accessToken) || "", accountKey: sessionStorage.fetch(bConst.accountKey)}),
      }, 
    })
    .then((response) => {
      if (response.data.status===bConst.s_Success) { 
        setNewPwNeeded(false)
        result = true
      } else {
        console.error('AuthContex.savePW:', response.data.status)
        result = false; 
      } 
    })
    .catch((error) => {
      console.error('AuthContex.savePW:', error)
      result = false;
    })
    return result
  } 

  const checkPW = async(PW) => {
    let result = false
    await axios.post("/api/auth/check_password", {"isMedw": user.isMedw, "userId": user.id, "password": PW}, {
      headers: {
      authorization: JSON.stringify({accessToken: sessionStorage.fetch(bConst.accessToken) || "", accountKey: sessionStorage.fetch(bConst.accountKey)}),
      }, 
    })
    .then((response) => {
      if (response.data.status===bConst.s_Success) { 
        result = true
      } else {
        result = false; 
      } 
    })
    .catch((error) => {
      console.error('AuthContex.savePW:', error)
      result = false;
    })
    return result
  } 

  // check if token in sessionstorage
  React.useEffect(()  => {
    if( !accesstoken || !refreshtoken || !user ) {
      try {
        const refreshtokenS = sessionStorage.fetch(bConst.refreshToken);
        const accesstokenS = sessionStorage.fetch(bConst.accessToken);
        const accountkeyS = sessionStorage.fetch(bConst.accountKey);
        if (sessionStorage.fetch(bConst.tfaVerified)===bConst.tfaValue) {setTfaVerified(true)} else {setTfaVerified(false)}

        // setTfaVerified( sessionStorage.fetch(bConst.tfaVerified));
        setLoading(true);

        // token available in browserstorage?
        if( refreshtokenS && accesstokenS && accountkeyS ) { 
          // refresh accesstoken
          axios.post('/refresh', {refreshToken: refreshtokenS})
          .then((refreshResponse) => {
          //  console.log('new token: '+refreshResponse.data.accessToken)         
            if( refreshResponse && refreshResponse.data && refreshResponse.data.accessToken ) {
              // set refreshToken and new accesToken in context
              setRefreshToken(refreshtokenS);
              setAccessToken(refreshResponse.data.accessToken);
              // setTfaVerified(tfaVerifiedS)

              // save new accestoken in sessionstore
              sessionStorage.save(bConst.accessToken, refreshResponse.data.accessToken);

              // get corresponding user login info
              const { userId, isMedw } = jwtDecode(refreshtokenS);                      
              axios.post('/api/get_loginuser', {userId: userId, isMedw: isMedw}, {
                headers: {
                  authorization: JSON.stringify({accessToken: refreshResponse.data.accessToken || "", accountKey: accountkeyS}),
                }
              })
              .then((response) => {
                if( response.data && response.data.status === bConst.s_Success ) {
                  // set user info in contex
                  setUser(response.data.result.user);
                  setSettings(response.data.result.settings)

                  fillQR(response.data.result.tfaStatus)
                  if (response.data.result.isMedw===1) { setNewPwNeeded(false) } else { setNewPwNeeded((new Date(response.data.result.pwDate)<=new Date())) }

                  axios.post("/versionStr")
                  .then((response) => {
                    setVersionStr(response.data);                  
                    setLoading(false);
                  })
                  .catch(error => {
                    console.error('AuthContext.useeffect: Error in versionStr', error)
                    logout();
                    setLoading(false);
                  })
                } else {
                  console.error('AuthContext.useeffect: Error in getloginUser', 'get_loginuser not succesfull')
                  setLoading(false);  
                }
              })  
              .catch(error => {
                console.error('AuthContext.useeffect: Error in loginuser', error)
                logout();
                setLoading(false);
              })
            } else {
              console.error('AuthContext.useeffect: Error no valid refrestoken.')
              logout();
              setLoading(false);
            }
          })

          .catch(error => {
            console.error('AuthContext.useeffect: Error in refresh', error)
            logout();
            setLoading(false);
          });

        } else {                    
          // console.error('AuthContext.useeffect: No session data available');
          setLoading(false);
          //logout()
        }

      } 
      catch(error) { 
        console.error('AuthProvider.useEffect',error);
        setLoading(false);
      }
    }
    setLoading(false);
  } ,[] );  
  // } ,[accesstoken, refreshtoken, logout, user, sessionStorage] ); 

  // React.useEffect( () => {
  //      console.log('Lege useEffect');
  // }, [errorMsg, user, loading, getTokens ]);

  const doSignin = () => { 
    if (loading) {
      // console.log('Loading: '+loading)
      return (
        <Backdrop open={true}><CircularProgress /></Backdrop>
      ) 
    } else {
      // if  ( user && accesstoken && refreshtoken && tfaVerified===true && newPwNeeded===false ) {
      if  ( user && tfaVerified && !newPwNeeded ) {
          if (!accesstoken && !pwNeeded) { get_webtokens() }
          if (accesstoken) {
            // console.log('Token found')
            return (  
              <>
               {props.children}
              </> 
            ) 
           } else {
            // console.log('Token not found')
              return (
                <Backdrop open={true}><CircularProgress /></Backdrop>
              ) 
            }
      } else {
        return (
          <LoginLayout>         
            <Login />
          </LoginLayout>          
        )
      }
    }
  }   
  
  return(
    <AuthContext.Provider value={{
      accesstoken, 
      refreshtoken,
      setAccessToken, 
      login, 
      logout,
      logout2,
      verifyTfa,
      saveTfa,
      setScanQR,
      savePW,
      checkPW,
      scanQR,
      QR,
      newPwNeeded,
      user,
      settings,
      tfaVerified,
      setVerified,
      errorMsg,
      setErrorMsg, 
      loading}}
    >
    { doSignin() }

    </AuthContext.Provider>

)}

export { AuthContext, AuthProvider }
export default AuthContext;


