//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React, { useEffect,useState, useContext } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Hidden, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {navItems, configerSidebar} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import AuthContext from "../contexts/AuthContext";

const drawerWidth = 215;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, settings } = useContext(AuthContext);

  const openSideBar = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setMobileOpen(false);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  configerSidebar(user.isMedw, settings.MedwDeclaraties);    
  
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth} }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleClose}
            closeSideBar={handleClose}
            items={navItems}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={navItems}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Navbar openSideBar={openSideBar} />
        <MainContent p={isLgUp ? 5 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root> 
  );
};

export default MainLayout;
